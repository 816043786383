<template>
  <div class="py-8">
    <PlanPricing @buyPlan="handleBuyPlanEvent" />
  </div>
  <Dialog
    header="Activación de plan"
    v-model:visible="displayModal"
    :closable="false"
    :style="{ width: '450px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <p>
        Está seguro de que desea activar el plan
        <span class="font-bold">{{ buyPayload.planType }}</span>
      </p>
      <p>
        Tendrá un coste de
        <span class="font-bold">{{ buyPayload.amount }}€</span> al mes
      </p>
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        @click="closeConfirmation"
        class="p-button-danger"
      />
      <Button
        label="Confirmar"
        icon="pi pi-check"
        @click="buyPlan"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import PlanPricing from '../components/PlanPricing.vue';
import api from '../api/Acutame';

export default {
  data() {
    return {
      displayModal: false,
      buyPayload: {
        amount: 0,
        callback: null,
        additionalOrgs: 0,
        planType: 'FOO',
      },
    };
  },
  components: {
    PlanPricing,
  },
  methods: {
    handleBuyPlanEvent(planType, additionalOrgs, amount, callback) {
      this.buyPayload = {
        planType,
        additionalOrgs,
        amount,
        callback,
      };
      this.displayModal = true;
    },
    async buyPlan() {
      this.displayModal = false;
      const { planType, additionalOrgs, callback } = this.buyPayload;
      const { url } = await api.buyPlan(planType, additionalOrgs);
      if (url) {
        window.location.assign(url);
      } else {
        callback?.();
      }
    },
    closeConfirmation() {
      this.displayModal = false;
      this.buyPayload.callback?.();
    },
  },
};
</script>
